/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import LinkedInTag from 'react-linkedin-insight';
import { configureLogger } from '@src/helpers/loggerConfig';

import { getLinkedInPartnerId } from '@src/helpers/configValues';

export const onInitialClientRender = () => {
  configureLogger();
  LinkedInTag.init(getLinkedInPartnerId());
};
