import logdown from 'logdown';

const logger = logdown('aw-nodeEnvSwitch');

const checkKnownEnvironment = nodeEnv => {
  if (
    nodeEnv !== 'production' &&
    nodeEnv !== 'development' &&
    nodeEnv !== 'test'
  ) {
    logger.error('Unknown environment NODE_ENV=', nodeEnv);
  }
};

export const nodeEnvSwitch = (
  implementations,
  nodeEnv = process.env.NODE_ENV,
) => {
  const isImplemented = Object.hasOwnProperty.call(implementations, nodeEnv);
  const isProductionImplemented = Object.hasOwnProperty.call(
    implementations,
    'production',
  );
  if (nodeEnv === 'development' || nodeEnv === 'test') {
    // eslint-disable-next-line no-console
    console.assert(isImplemented, 'Cannot find proper implementation.', {
      configuration: nodeEnv,
    });
  }

  if (isImplemented) {
    return implementations[nodeEnv];
  } else if (isProductionImplemented) {
    logger.warn('Fallback to production implementation');
    return implementations['production'];
  } else {
    logger.error('Not implemented.');
    return undefined;
  }
};

checkKnownEnvironment(process.env.NODE_ENV);
