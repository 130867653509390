import logdown from 'logdown';

import { nodeEnvSwitch } from '@src/helpers/nodeEnvSwitch';

const development = () => {
  try {
    localStorage.debug = 'aw*';
    const logger = logdown('aw-configureLogger');
    logger.info('Logger is turned on.');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

const production = () => {
  try {
    localStorage.debug = 'aw*prod';
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const configureLogger = nodeEnvSwitch(
  {
    production,
    development,
    test: development,
  },
  process.env.NODE_ENV,
);
