module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-122941751-2"],"gtagConfig":{"optimize_id":"GTM-KWT94CL","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"exclude":["/404"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
