// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-bom-help-jsx": () => import("./../../../src/pages/bomHelp.jsx" /* webpackChunkName: "component---src-pages-bom-help-jsx" */),
  "component---src-pages-capabilities-jsx": () => import("./../../../src/pages/capabilities.jsx" /* webpackChunkName: "component---src-pages-capabilities-jsx" */),
  "component---src-pages-certifications-jsx": () => import("./../../../src/pages/certifications.jsx" /* webpackChunkName: "component---src-pages-certifications-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pcb-prototypes-easy-reliable-fast-jsx": () => import("./../../../src/pages/pcb-prototypes-easy-reliable-fast.jsx" /* webpackChunkName: "component---src-pages-pcb-prototypes-easy-reliable-fast-jsx" */),
  "component---src-pages-pcb-prototypes-no-risk-guaranteed-jsx": () => import("./../../../src/pages/pcb-prototypes-no-risk-guaranteed.jsx" /* webpackChunkName: "component---src-pages-pcb-prototypes-no-risk-guaranteed-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

