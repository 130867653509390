import urljoin from 'urljoin';
import * as ValueHelpers from '@src/helpers/valueHelpers';

export const validateNonEmptyString = (name, value) => {
  if (!ValueHelpers.isNonEmptyString(value)) {
    throw new Error(`${name} has to be non-empty string, but it is "${value}"`);
  }
  return value;
};

// env variables
export const getAppUrl = () =>
  validateNonEmptyString('APP_URL', process.env.APP_URL);

export const getAdcoEmail = () =>
  validateNonEmptyString('ADCO_EMAIL', process.env.ADCO_EMAIL);

export const getAdcoPhone = () =>
  validateNonEmptyString('ADCO_PHONE', process.env.ADCO_PHONE);

export const getRecaptchaSiteKey = () =>
  validateNonEmptyString('RECAPTCHA_SITE_KEY', process.env.RECAPTCHA_SITE_KEY);

export const getLinkedInPartnerId = () =>
  validateNonEmptyString(
    'LINKEDIN_INSIGHT_PARTNER_ID',
    process.env.LINKEDIN_INSIGHT_PARTNER_ID,
  );

// derived
export const getAppLoginUrl = () => urljoin(getAppUrl(), '/login');
